@import 'variables.scss';

.rating-text {
  &--info {
    font-style: italic;
    color: $xpo-grey--900;
  }
  &--alert {
    color: $xpo-red--150;
  }
}

.rating-warning-text {
  background-color: $corrections-warning-background;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
